.activeInfo {
  padding: 16px;
  background: #FFFFFF;
  margin-top: 20px;
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 10px;

  span {
    min-width: 200px;
    display: inline-block;
    margin-right: 50px;
    line-height: 1.8;
  }
}

.maxWidth {
  width: 100%;
  display: flex;
}

.commonClass {
  background: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  height: calc(100vh - 265px);
  overflow-y: scroll;
  //float: left;
}

.step {
  width: 110px;
  text-align: center;
  padding: 16px;

  .stepList {
    margin-left: 0;
    color: #999999;
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    border: none;
  }

  span.tit {
    display: block;
    line-height: 1.8;
  }

  .activeClass {
    color: #FFAB57;
    font-weight: bold;
  }

  .beforeClass {
    color: #333333;
  }


}

.stepContent {
  margin-left: 10px;
  width: 90%;
  padding: 16px 0;

  h3.titHead {
    width: 120px;
    height: 32px;
    background: #0FBAF1;
    border-radius: 0 8px 8px 0;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    font-size: 18px;
  }

  .smallWidth {
    width: 180px;
  }
}

.detail {
  padding: 16px 16px 0;
  height: 94%;
  overflow-y: scroll;

  .fromSave {
    margin-bottom: 0;
  }

  .title {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    padding: 0 0 12px 12px;
    margin-bottom: 14px;
    font-weight: bold;
    font-size: 16px;
  }

  .tableMember ::v-deep {
    margin-bottom: 14px;
    margin-left: 12px;
    width: calc(100% - 12px);
    font-size: 14px;
    color: #333;

    .el-form-item {
      margin-bottom: 0;

      .el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .formAdd {
      background: #F7FBFF;

      .el-input__inner {
        border: none;
        background: transparent;
        padding-left: 0;
      }

      .el-input__prefix {
        display: none;
      }
    }

    .addColor .el-button--text {
      color: #4F9AFE;
    }

    .editColor .el-button--text {
      color: #FFAB57;
    }
  }

  .backReturn {
    border-bottom: 1px solid rgba(130, 182, 253, 0.5);
    margin-bottom: 14px;
    justify-content: space-between;
    padding: 0 0 12px 12px;

    .title {
      margin-bottom: 0;
      border: none;
      padding: 0;
    }

    .return {
      background-color: #4F9AFE;
      color: #FFFFFF;
    }
  }

  .return {
    border-color: #4F9AFE;
    color: #4F9AFE;
  }
}

.desc {
  line-height: 2;
  font-size: 14px;
  color: #333333;
  text-align: justify;
  margin-bottom: 14px;
  padding-left: 12px;

  span {
    display: inline-block;
    min-width: 200px;
    margin-right: 10px;
  }

}

//方案审批
.timeLine ::v-deep {
  .el-timeline {
    padding-left: 20px;
  }

  .el-timeline-item__dot {
    left: -9px;
  }

  .dot {
    width: 28px;
    height: 28px;
    background: #FFAB57;
    border-radius: 50%;
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;

    &.blue {
      background: #57B6FF;
    }
  }

  .el-timeline-item__tail {
    border-left-style: dashed;
    height: 64%;
    top: 35px;
  }

  .el-timeline-item__content {
    line-height: 1.8;
    margin-left: 15px;
  }

  .stepF {
    h3 {
      min-width: 300px;
    }

    .router-link-active {
      font-weight: 500;
      color: #FFAB57;
      margin-left: 5%;
    }
  }

  .approverDate {
    color: #666666;
  }

  .approveResult {
    color: #FFAB57;

    span {
      margin-right: 50px;
    }
  }
}

//检疫
.btn {
  margin: 15px 12px;

  .el-button {
    border-color: #4F9AFE;
    background-color: #4F9AFE;
  }
}

.qualified {
  font-weight: bold;
  color: #0FBAF1;
  margin: 0 0 12px 12px;
  padding-left: 12px;
  display: block;
  position: relative;

  &:before {
    position: absolute;
    width: 4px;
    height: 14px;
    background: #0FBAF1;
    border-radius: 2px;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

//实验报告
.upload-demo ::v-deep {
  margin-bottom: 14px;

  .el-upload-dragger {
    background: rgba(72, 150, 255, 0.05);
    border-color: #4896FF;
    height: 184px;
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    p {
      margin: 10px 0;
    }

    .el-button {
      background-color: #4896FF;
      width: 140px;
    }
  }

  .el-icon-upload {
    margin: 0;
    background-image: -webkit-linear-gradient(bottom, rgba(72, 150, 255, 1), rgba(48, 136, 254, 0.6));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

}

//动物分笼
.addTab {
  width: 32px;
  height: 32px;
  border: 1px solid #4F9AFE;
  padding: 0;
  border-radius: 0;
  color: #4F9AFE;
  margin-bottom: 14px;
  margin-left: 14px;

  &:hover {
    background-color: transparent;
  }
}

.tabsCage ::v-deep {
  margin-left: 12px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  .el-tabs__item {
    min-width: 72px;
    height: 32px;
    border: 1px solid #4F9AFE;
    text-align: center;
    padding: 0 10px !important;
    line-height: 32px;
    margin-right: 16px;
    color: #4F9AFE;

    &.is-active {
      color: #FFFFFF;
      background-color: #4F9AFE;
    }
  }

  &.el-tabs__nav-wrap::after, .el-tabs__active-bar {
    display: none;
  }

  .tableMember {
    margin-left: 0;
  }

  .desc {
    padding-left: 0;
  }

  .addClass {
    background-color: rgba(213, 234, 255, 0.2);

    .el-input__inner {
      border: none;
      background-color: transparent;
      padding: 0;
    }

    &:hover {
      background-color: rgba(213, 234, 255, 0.2);
    }
  }
}

.add-box {
  display: inline-block;
  vertical-align: top;
}

//建模
.stepModeling {
  margin-left: 20px;
  font-weight: 500;
  color: rgba(15, 186, 241, 0.3);

  .stepItem {
    display: inline-block;
    margin: 0 10px;
    padding: 0 10px;
    height: 32px;
    border: 1px solid rgba(15, 186, 241, 0.3);
    border-radius: 8px;
    line-height: 32px;
    text-align: center;
    color: rgba(15, 186, 241, 0.3);
    font-size: 14px;
  }

  .activeClass {
    color: #0FBAF1;

    .stepItem {
      color: #0FBAF1;
      border: 1px solid #0FBAF1;
    }
  }
}

.groupList {
  flex-flow: wrap;
}

.modeFor {
  .smallWidth {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}


.delIns {
  border-color: #BBBBBB;
  padding: 9px 10px;

  &:hover {
    border-color: #BBBBBB;
  }
}

.addInstru {
  width: 120px;
  height: 32px;
  border: 1px solid #4F9AFE;
  color: #4F9AFE;
  margin-bottom: 10px;

  &:hover, &:focus {
    background-color: transparent;
    color: #4F9AFE;
  }
}

.smallTit {
  color: #333333;
  font-size: 14px;
  margin-bottom: 5px;
}

.unitBtn {
  margin-left: 16px;
  border-color: #FFAB57;
  background-color: #FFAB57;
}

.unit {
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  border: 1px solid #BBBBBB;
  margin-top: 16px;

  .smallWidth ::v-deep {
    width: 50px;

    .el-input__inner {
      padding: 0;
      border-width: 0 0 1px 0;
    }
  }
}

.remarkTit {
  font-size: 14px;
  font-weight: 400;
  color: #4896FF;
  margin-bottom: 16px;
}

.record-table {
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  color: #333;

  .el-button--default {
    height: 24px;
    line-height: 24px;
    padding: 0 15px;
  }

  .finished {
    border-color: #09BEE9;
    color: #09BEE9;
    background: rgba(9, 190, 233, 0.1);
  }

  .suspended {
    border-color: #BBBBBB;
    color: #BBBBBB;
    background: rgba(187, 187, 187, 0.1);
  }

  .experimentCo {
    border-color: #FFAB57;
    color: #FFAB57;
    background: rgba(255, 171, 87, 0.1);
  }

  .approval {
    border-color: #FF7B57;
    color: #FF7B57;
    background: rgba(255, 123, 87, 0.1);
  }

  .el-button--text {
    color: #FFAB57;
  }
}

.cage {
  margin-top: 16px;
  color: #333333;
  font-size: 14px;

  .info {
    line-height: 1.8;
  }
}

.line {
  margin: 0 10px;
  display: inline-block;
  height: 1px;
  width: 20px;
  background: #999999;
}

.submitRange {
  margin-left: 20px;
  background: #4F9AFE;
  color: #FFFFFF;
  border: none;
}

.groupExper {
  width: 120px;
  height: 32px;
  border: 1px solid #4F9AFE;
  margin-bottom: 10px;
  margin-right: 16px;
  text-align: center;
  color: #4F9AFE;
  cursor: pointer;

  .el-button {
    border: none;
    padding: 0;
    margin-left: 10px;
    color: #4F9AFE;
    background: transparent;
  }

  &.active {
    background: #4F9AFE;
    color: #FFFFFF;

    .el-button {
      color: #FFFFFF;
    }
  }
}

.submitGroup {
  padding: 0 20px;
  display: block;
  margin-left: 0;
  height: 32px;
  background: #4F9AFE;
  border: none;
  color: #FFFFFF;
  font-size: 14px;
}

.tableMember ::v-deep.isClass {
  background-color: rgba(213, 234, 255, 0.2);

  .el-input__inner {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  &:hover {
    background-color: rgba(213, 234, 255, 0.2);
  }

  .ml-2 {
    margin-left: 10px;
  }

  .el-checkbox {
    color: #333333;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #FFFFFF;
    border-color: #4F9AFE;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #333333;
  }

  .el-checkbox__inner::after {
    border-color: #4F9AFE;
  }
}
